@import '../../../../../shared/utils/variable.scss';

@mixin main-font {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 16px;
	line-height: 21.67px;
	text-align: center;
}

@mixin secondary-font($size, $weight, $height, $spacing) {
	font-family: 'Open Sans', sans-serif;
	font-size: $size;
	font-weight: $weight;
	line-height: $height;
	letter-spacing: $spacing;
	text-align: center;
}

@mixin all-items {
	padding: 16px;

	.label {
		@include main-font();
		color: var(--tertiary-dark, #333333);
	}
}

.container::-webkit-scrollbar {
	display: none;
}

.container {
	background: var(--background-color, #ded9c7);
	display: flex;
	position: sticky;
	overflow-y: scroll;
	height: 100dvh;
	-ms-overflow-style: none;
	top: 0;
	width: 271px;
	align-items: center;
	flex-direction: column;
	padding: 4px 8px 0px;

	a {
		text-decoration: none !important;
		color: #ffffff !important;
	}
}

.header {
	background: var(--secondary-color, #34322c);
	padding: 16px;
	width: stretch;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #ffffff;
	margin-bottom: 8px;

	.logo {
		width: 50px;
		height: 30.6px;
		background: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/dine-rewards-logo-white.svg')
			center left no-repeat;
		background-size: contain;
	}

	.user {
		width: 20px;
		height: 20px;
	}
}

.user-icon {
	fill: #ffffff;
}

.item-container {
	position: relative;
	width: stretch;
	margin-bottom: 8px;
	background-color: #ffffff;

	a:hover {
		background-color: #f9f9f5;
	}
}

.rewards {
	@include all-items();
	display: flex;
	gap: 16px;
	flex-direction: column;
	align-items: center;
	padding-left: 24.5px;
	padding-right: 24.5px;
	cursor: pointer;
}

.rewards-counter {
	@include secondary-font(20px, 700, 27.24px, inherit);
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000000;
	width: 206px;
	height: 59px;
}

.points-tracker {
	@include all-items();
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	height: 162px;
	cursor: pointer;

	.subtitle {
		@include secondary-font(12px, 400, 14.4px, 0.01em);
		color: var(--tertiary-dark, #333333);
	}

	.progress-bar-limits {
		@include secondary-font(16px, 400, 21.79px, inherit);
		color: var(--tertiary-dark, #333333);
		display: flex;
		justify-content: space-between;
		margin-left: 8px;
		margin-right: 8px;
	}
}

.progress-bar-container {
	width: 100%;
	height: 30px;
	border-radius: 4px;
	background: var(--Tertiary-Ligth, rgba(237, 237, 237, 1));
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1) inset;

	.progress-bar {
		width: 0%;
		margin-bottom: 8px;
		height: inherit;
		border-radius: 4px;
		background: var(--Primary-Variant, rgba(171, 101, 3, 1));
		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1) inset;
		transition: width 1s ease-in-out;
	}
}

.menu {
	width: stretch;
}

.menu-item {
	@include all-items();
	display: flex;
	align-items: center;
	justify-content: center;
	height: 56px;
	cursor: pointer;

	.icon {
		width: 24px;
		height: 24px;
		color: #8a8a8a;
	}

	.label {
		flex-grow: 1;
		height: 24px;
	}
}

.footer {
	@media (min-width: $TABLET_BREAKPOINT) {
		display: none;
	}
}

.button {
	cursor: pointer;
}
