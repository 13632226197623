@import '../../../shared/utils/variable.scss';

@mixin center-flex($direction) {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: $direction;
}

@mixin text-format($size) {
	color: var(--tertiary-dark, #333333) !important;
	font-family: 'Open Sans', sans-serif;
	font-size: $size;
	line-height: 0.75625em;
}

.container {
	@include center-flex(column);
	position: relative;
	z-index: 1;
	padding: 1.5em 0 1em 0;
	gap: 1.0625em;
	background: var(--background-color);

	a:hover {
		cursor: pointer;
	}

	@media (max-width: $TABLET_BREAKPOINT) {
		display: none;
	}
}

.container-mobile {
	@include center-flex(column);
	position: relative;
	z-index: 1;
	padding: 1em 0.5em 1em 0.5em;
	background: var(--background-color);
	display: none;

	overflow: hidden;

	@media (max-width: $TABLET_BREAKPOINT) {
		display: block;
		padding: 0.5em 0.5em 0.5em 0.5em;
	}
}

.links {
	@include center-flex(row);
	@include text-format(12px);
	gap: 8px;

	a,
	a:link,
	a:visited,
	a:active,
	a:focus,
	a:hover {
		color: var(--tertiary-dark, #333333);
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}

	@media (max-width: $TABLET_BREAKPOINT) {
		gap: calc(1em);
	}
}

.logos {
	@include center-flex(row);
	gap: 3em;
	width: 100%;
	flex-wrap: wrap;
	padding: 18px 0;

	@media (max-width: $TABLET_BREAKPOINT) {
		row-gap: 0px;
		padding: 40px 8px 8px 8px;
		column-gap: 32px;

		img {
			width: 64px;
		}
	}

	@media (max-width: 400px) {
		img {
			width: 50px;
		}
	}
}

.copyright {
	@include text-format(0.625em);
	text-align: center;

	@media (max-width: $TABLET_BREAKPOINT) {
		@include text-format(10px);
	}
}

.linkText {
	@include text-format(10px);
	text-align: center;
	padding-top: 8px;
	padding-bottom: 16px;

	a,
	a:link,
	a:visited,
	a:active,
	a:focus,
	a:hover {
		text-decoration: underline !important;
		text-align: center;
		color: var(--tertiary-dark, #333333) !important;
	}
}

.carrabbas-logo {
	img {
		width: 86px;
		height: 20px;
	}

	@media (max-width: 400px) {
		img {
			width: 50px;
		}
	}
}
